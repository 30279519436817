import { useOutsideClick } from "@chakra-ui/hooks";
import {
  Box,
  Button,
  ChakraProvider,
  Divider,
  HStack,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import type { CalendarDate, CalendarValues } from "datepicker";
import {
  Calendar,
  CalendarControls,
  CalendarDays,
  CalendarMonth,
  CalendarMonthName,
  CalendarMonths,
  CalendarNextButton,
  CalendarPrevButton,
  CalendarWeek,
} from "datepicker";
import dayjs from "dayjs";
import React from "react";
import { T } from "~/libs/i18n";
import { theme } from "./date-range-picker-calendar";

function isRangeSelection(object: any): object is CalendarValues {
  return "start" in object || "end" in object;
}

export const DateRangePicker = ({
  name,
  startInputName,
  endInputName,
  startDate,
  endDate,
  displayMonths,
  placeholder,
  onChange,
}: {
  name?: string;
  startInputName?: string;
  endInputName?: string;
  startDate?: string | null | undefined;
  endDate?: string | null | undefined;
  displayMonths?: number;
  placeholder?: string[];
  onChange?: (dates: CalendarValues) => void;
}) => {
  const parseInputDate = (dateLike: string | null | undefined) =>
    dateLike ? dayjs(dateLike, "YYYY-MM-DD").toDate() : undefined;

  const firstDate = parseInputDate(startDate);
  const secondDate = parseInputDate(endDate);

  const [dates, setDates] = React.useState<CalendarValues>({
    start: firstDate,
    end: secondDate,
  });

  const [rawInputValues, setRawInputValues] = React.useState({
    start: firstDate && startDate ? startDate : "",
    end: secondDate && endDate ? endDate : "",
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);
  const calendarRef = React.useRef(null);
  const startInputRef = React.useRef<HTMLInputElement>(null);
  const endInputRef = React.useRef<HTMLInputElement>(null);

  const MONTHS = displayMonths || 1;

  const handleSelectDate = (dates: CalendarValues | CalendarDate) => {
    if (!isRangeSelection(dates)) {
      return;
    }

    if (dates.start && dates.end && dates.start > dates.end) {
      return;
    }

    setDates(() => dates);

    setRawInputValues({
      start: dayjs(dates.start, {}, true).isValid()
        ? dayjs(dates.start).format("YYYY-MM-DD")
        : "",
      end: dayjs(dates.end, {}, true).isValid()
        ? dayjs(dates.end).format("YYYY-MM-DD")
        : "",
    });

    if (onChange) {
      onChange(dates);
    }
  };

  const handleInputChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setRawInputValues({
      ...rawInputValues,
      [target.name]: target.value,
    });
  };

  const handleInputBlur = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(target.value);
    const isValidDate = dayjs(date, {}, true).isValid();

    if (!isValidDate) return;

    handleSelectDate({ ...dates, [target.name]: date });
  };

  useOutsideClick({
    ref: calendarRef,
    handler: onClose,
    enabled: isOpen,
  });

  const [startDatePlaceholder, endDatePlaceholder] = placeholder || [
    "from",
    "to",
  ];

  return (
    <Popover
      placement="auto-start"
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialRef}
      isLazy
    >
      <PopoverTrigger>
        <HStack
          spacing={2}
          justifyContent={"space-between"}
          bg={"inset"}
          rounded="md"
          px={2}
          onClick={onOpen}
          ref={initialRef}
        >
          <Text color={"muted"}>{startDatePlaceholder}</Text>
          <Input
            size={"md"}
            name={`${startInputName}`}
            value={rawInputValues.start}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            ref={startInputRef}
            autoComplete={"off"}
          />
          <Text color={"muted"}>{endDatePlaceholder}</Text>
          <Input
            size={"md"}
            name={`${endInputName}`}
            value={rawInputValues.end}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            ref={endInputRef}
            autoComplete={"off"}
          />
        </HStack>
      </PopoverTrigger>

      <PopoverContent
        p={0}
        w="min-content"
        border="none"
        outline="none"
        _focus={{ boxShadow: "none" }}
        ref={calendarRef}
      >
        <Calendar
          value={dates}
          onSelectDate={handleSelectDate}
          months={MONTHS}
          allowOutsideDays
          highlightToday
        >
          <PopoverBody p={0}>
            <Box position="relative">
              <CalendarControls>
                <CalendarPrevButton />
                <CalendarNextButton />
              </CalendarControls>

              <CalendarMonths>
                {[...Array(MONTHS).keys()].map(m => (
                  <CalendarMonth key={m} month={m}>
                    <CalendarMonthName />
                    <CalendarWeek />
                    <ChakraProvider theme={theme}>
                      <CalendarDays />
                    </ChakraProvider>
                  </CalendarMonth>
                ))}
              </CalendarMonths>
            </Box>
            <Divider />
            <HStack spacing={1} p={4} justifyContent={"space-between"} flex={1}>
              <Button
                size="xs"
                onClick={() => {
                  setDates({ start: undefined, end: undefined });
                  setRawInputValues({ start: "", end: "" });
                }}
              >
                <T>Clear</T>
              </Button>
              <Button size="xs" onClick={onClose}>
                <T>Apply</T>
              </Button>
            </HStack>
          </PopoverBody>
        </Calendar>
      </PopoverContent>
    </Popover>
  );
};
